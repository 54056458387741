import "reflect-metadata";
import { CombineSubscriptions, DestroySubscribers } from "ngx-destroy-subscribers";
import { Unsubscribable } from "rxjs";
import { filter } from "rxjs/operators";
import { Controller } from '@stimulus/core';
import Container from "typedi";
import { secureFetch } from "../auth/secure_fetch_function";
import { CookieHelper } from "../helpers/cookie-helper";
import { ModalHelper } from "../helpers/modal-helper";
import { LikeToken } from "../subjects/like_subject";
import { SessionToken } from "../subjects/session_subject";
import {ToastType} from "../models/toast";
import {ToastHelper} from "../helpers/toast-helper";

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class LikeController extends Controller {
  public static LIKE_COOKIE_PATH = 'likes';

  public static targets = ['counter'];
  private readonly counterTarget!: Element;
  private readonly hasCounterTarget: boolean;
  private emittedSelf = false;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect() {
    this.subscriber = Container.get(SessionToken).subscribe(session => {
      if (session && session.user) {
        this.element.classList.toggle('liked', session.user.likes.indexOf(this.data.get('itemId')) > -1);
      }
    });
    this.subscriber = Container.get(LikeToken).pipe(
      filter(like => like.itemId === parseInt(this.data.get('itemId'), 10)),
      filter(() => {
        if (this.emittedSelf) {
          this.emittedSelf = false;
          return false;
        }
        return true;
      })
    ).subscribe(like => {
      like.action === 'create' ? this.createLike(true) : this.deleteLike(true);
    });
    const previousLikes: string[] = CookieHelper.getSerialized(LikeController.LIKE_COOKIE_PATH);
    if (previousLikes && previousLikes.indexOf(this.data.get('itemId')) > -1) {
      this.element.classList.add('liked');
      this.element.classList.remove('disliked');
    } 
  }

  public async toggle(event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.isLiked() ? this.deleteLike() : this.createLike();
  }

  private isLiked(): boolean {
    return this.element.classList.contains('liked');
  }

  private async createLike(visualOnly = false) {
    this.element.classList.add('liked');
    this.element.classList.remove('disliked');
    this.changeCounter(1);
    if (!visualOnly) {
      const session = Container.get(SessionToken).getValue();
      if (session && session.user) {
        const url = this.data.get('createUrl');
        const result = await secureFetch(url, { method: 'POST' });

        if (result.status === 401) {
          this.element.classList.remove('liked');
          this.element.classList.add('disliked');
          this.changeCounter(-1);
          this.handleUnauthorized(result)
        } 
      }
      const previousLikes = CookieHelper.getSerialized(LikeController.LIKE_COOKIE_PATH) || [];
      const newLikes = previousLikes.concat(this.data.get('itemId'));
      CookieHelper.setSerialized(LikeController.LIKE_COOKIE_PATH, newLikes);
      this.emittedSelf = true;
      Container.get(LikeToken).next({
        itemId: parseInt(this.data.get('itemId'), 10),
        action: 'create'
      });
      this.showToast();
    }
  }

  private async deleteLike(visualOnly = false) {
    this.element.classList.remove('liked');
    this.element.classList.add('disliked');
    this.changeCounter(-1);
    if (!visualOnly) {
      const session = Container.get(SessionToken).getValue();
      if (session && session.user) {
        const url = this.data.get('deleteUrl').replace(':itemId', this.data.get('itemId'));
        const result = await secureFetch(url, { method: 'DELETE' });

        if (result.status === 401) {
          this.element.classList.add('liked');
          this.element.classList.remove('disliked');
          this.changeCounter(1);
          this.handleUnauthorized(result);
        }
      }
      const previousLikes = CookieHelper.getSerialized(LikeController.LIKE_COOKIE_PATH);
      const newLikes = (previousLikes || []).filter(item => item !== this.data.get('itemId'));
      CookieHelper.set(LikeController.LIKE_COOKIE_PATH, newLikes);
      this.emittedSelf = true;
      Container.get(LikeToken).next({
        itemId: parseInt(this.data.get('itemId'), 10),
        action: 'delete'
      });
    }
  }

  private async handleUnauthorized(result) {
    const body = await result.json();
    ModalHelper.openModalWithUrl(body.resolution.url);
  }

  private changeCounter(increment: number) {
    if (this.hasCounterTarget) {
      const currentCount = parseInt(this.counterTarget.innerHTML, 10);
      this.counterTarget.innerHTML = `${currentCount + increment}`;
    }
  }

  private showToast() {
    ToastHelper.create(
        ToastType.LIKED_A_LISTING,
        '',
        { autohide: true, delay: 5000 }
    )
  }
}
