import { Controller } from '@stimulus/core';

export default class ConversationsController extends Controller {
  public static targets = ['details', 'conversation', 'headerConversations', 'messages', 'translate', 'textarea', 'noDirectContactAlert'];

  private readonly detailsTarget: HTMLElement;
  private readonly conversationTarget: HTMLElement;
  private readonly headerConversationsTarget: HTMLElement;
  private readonly messagesTarget: HTMLElement;
  private readonly translateTarget: HTMLElement;
  private readonly textareaTarget: HTMLTextAreaElement;
  private readonly noDirectContactAlertTarget: HTMLTextAreaElement;

  public connect() {
    this.scrollToBottom();
    this.textareaTarget.addEventListener('focus', () => {
      this.noDirectContactAlertTarget.classList.remove('d-none');
    });
    this.textareaTarget.addEventListener('input',this.autoGrow.bind(this));
  }

  public archive(event: Event) {
    alert("archive");
  }

  public mobileShowDetails(event: Event) {
    this.detailsTarget.classList.add('show');
  }

  public mobileHideDetails(event: Event) {
    this.detailsTarget.classList.remove('show');
  }

  public mobileShowConversation(event: Event) {
    this.conversationTarget.classList.add('show');
    this.scrollToBottom();
  }

  public mobileHideConversation(event: Event) {
    this.conversationTarget.classList.remove('show');
  }

  public toggleSearch(event: Event) {
    this.headerConversationsTarget.classList.toggle('show-search');
  }

  public removeTranslate(event: Event) {
    if (this.translateTarget) {
      this.translateTarget.remove();
    }
  }

  private scrollToBottom() {
    this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight;
  }
  
  private autoGrow() {
    // Check if the cursor is at the end of the textarea
    const isAtEnd = this.textareaTarget.selectionStart === this.textareaTarget.value.length;
  
    // Reset the height to 'auto' to recalculate the required height
    this.textareaTarget.style.height = 'auto';
  
    // Calculate the padding height
    const style = getComputedStyle(this.textareaTarget);
    const paddingTop = parseInt(style.paddingTop);
    const paddingBottom = parseInt(style.paddingBottom);
    const borderTop = parseInt(style.borderTopWidth);
    const borderBottom = parseInt(style.borderBottomWidth);
  
    // Calculate the scrollHeight of the textarea, which includes overflow content
    const contentHeight = this.textareaTarget.scrollHeight;
  
    // Set the new height of the textarea based on scrollHeight
    let newHeight = contentHeight + borderTop + borderBottom;
  
    // Limit the maximum height to 4 lines
    const lineHeight = parseInt(style.lineHeight);
    const maxHeight = (lineHeight * 4) + paddingTop + paddingBottom + borderTop + borderBottom;
    newHeight = Math.min(newHeight, maxHeight);
  
    // Set the new height of the textarea
    this.textareaTarget.style.height = `${newHeight}px`;
  
    // Scroll to the bottom if the new height exceeds the maximum allowed and the cursor is at the end
    if (newHeight === maxHeight && isAtEnd) {
      this.textareaTarget.scrollTop = this.textareaTarget.scrollHeight;
    }
  }
  
  
}
